/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "bg-none text-black border-transparent hover:border-cherry-blossom-300 hover:bg-cherry-blossom-300 hover:text-white focus:border-cannon-pink-400 focus:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 focus:text-white active:border-cherry-blossom-300 active:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 active:text-white",
  "colors_filled": "bg-cherry-blossom-300 text-black border-transparent hover:border-cherry-blossom-500 hover:bg-cherry-blossom-500 hover:text-white focus:border-cannon-pink-400 focus:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 focus:text-white active:border-cherry-blossom-300 active:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 active:text-white",
  "colors_border": "border-cherry-blossom-500 text-cherry-blossom-500 hover:border-cherry-blossom-300 hover:bg-cherry-blossom-300 hover:text-white focus:border-cannon-pink-400 focus:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 focus:text-white active:border-cherry-blossom-300 active:bg-gradient-to-b from-cherry-blossom-300 to-cherry-blossom-200 active:text-white",
  "size_default": "w-8 h-8",
  "variant_default": "inline-flex items-center justify-center border focus:border-[3px] transition-all duration-200 ease-in-out rounded-full focus:outline-none active:outline-none"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;